import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import NavBar from "../../Navigation/Components/NavBar";
import Footer from "../Components/Footer";
import MainBanner from "../Components/MainBanner";
import SocialProof from "../Components/SocialProof";
import Feature from "../Components/Feature";
import TestimonialAlt from "../Components/TestimonialAlt";
import RequestDemo from "../Components/RequestDemo";
import WellnessBanner from "../../Shared/Images/WellnessPageBanner.png";
import WellnessHorzBars from "../../Shared/Images/Bienestar_barras_horz.png";
import WellnessCloudMap from "../../Shared/Images/Bienestar_abiertas.png";
import WellnessMap from "../../Shared/Images/Bienestar_mapa.png";
import Coparmex from "../../Shared/Images/Coparmex_bw.png";
import ProKarma from "../../Shared/Images/prokarma_bw.png";
import LAM from "../../Shared/Images/SistemaLAM_bw.png";
import Soles from "../../Shared/Images/Soles_bw.png";
import SiixSem from "../../Shared/Images/siix_bw.png";
import Gamboa from "../../Shared/Images/Gamboa.png";

function Wellness() {
  return (
    <Container fluid className="no-padding">
      <NavBar />

      <MainBanner
        title={"Los empleados felices logran cosas increibles."}
        subtitle={
          "Retroalimentación anónima clave para tomar iniciativas con confianza"
        }
        logo={WellnessBanner}
        buttonText={"Solicitar Demo"}
        linktext={"/demo"}
      />

      <SocialProof
        logo1={LAM}
        logo2={Soles}
        logo3={SiixSem}
        logo4={Coparmex}
        logo5={ProKarma}
      />

      <Col className="features">
        <Feature
          title={"Proceso seguro y anónimo"}
          subtitle={"Haz las preguntas correctas"}
          text={
            "Selecciona de nuestro catálogo de preguntas y cuestionarios diseñados por expertos. Utilízalos tal y como están o adáptalos de acuerdo a tu necesidad."
          }
          logo={WellnessHorzBars}
          classText={"feature"}
        />
      </Col>

      <TestimonialAlt
        text={
          "'Sphere fue un apoyo integral y muy completo para el fortalecimiento del equipo operativo, dando como resultado la claridad para las decisiones que tomamos y el camino a seguir para este proyecto en el cual se ve reflejado el crecimiento de la empresa. Sphere nos permitió desarrollar el equipo de líderes que trascienden.'"
        }
        profilepic={Gamboa}
        name={"Lic. Luis Gamboa"}
        profile={"Gerente de capital humano"}
        company={"Kalischatarra"}
      />

      <Col className="features">
        <Feature
          title={"Personalización adhoc"}
          subtitle={"Comprende la dinámica actual"}
          text={
            "Sphere te ayuda a identificar las áreas con mayor oportunidad. Reportes inmediatos, fáciles de interpretar para transformar los resultados en acciones de cambio."
          }
          logo={WellnessCloudMap}
          classText={"feature"}
        />
      </Col>

      <RequestDemo
        title={"¿Te gustaría conocer Sphere?"}
        text={
          "Descubre como puede ayudarte a construir una cultura de retroalimentación, desarrollo y alto desempeño."
        }
        buttonText={"Solicitar Demo"}
        linkText={"/demo"}
      />

      <Col className="features">
        <Feature
          title={"Aprovecha la información para entender a tu personal"}
          subtitle={"Cultura basada en retroalimentación"}
          text={
            "Gerentes y equipos cuentan con la información que necesitan para encontrar enfoque y crear planes basados en verdadero sentir y pensar de su gente"
          }
          logo={WellnessMap}
          classText={"feature"}
        />
      </Col>

      <Footer />
    </Container>
  );
}

export default Wellness;
