import React from "react";
import Container from "react-bootstrap/Container";
import Footer from "../../MainScreens/Components/Footer";
import LandingPageBanner from "../Components/LandingPageBanner";
import LandingFeature from "../../LandingPages/Components/LandingFeature";
import LandingFeaturedSnippet from "../Components/LandingFeaturedSnippet";
import Drawing from "../../Shared/Images/DemoRequestImage.png";
import Book from "../../Shared/Images/checklist.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function PerformanceChecklistLandingPage() {

  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/contacts/landing/performance`,
      data: {
        nickName: data.name,
        email: data.email,
      },
    });
    navigate("/thankyou-checklist");
  };
  return (
    <Container fluid className="no-padding">
      <LandingPageBanner
        title={
          "[CHECKLIST] Cómo administrar el desempeño del personal como lo hace el top 10% de las empresas."
        }
        textA={
          "Contar con un proceso de administración del desempeño no solo incrementa sustancialmente el rendimiento de la organización, sino que genera una cultura de co-responsabilidad y pertenencia."
        }
        textB={""}
        textC={""}
        logo={Book}
        handleSubmitMethod={handleSubmit}
      />
      <LandingFeature
        title={"¿Qué hay en la guía?"}
        text={"Con esta GUÍA conocerás:"}
        bullets={[
          {
            id: 1,
            header: "Cómo y porqué administrar el desempeño",
            description:
              "La administración del desempeño ayuda a la organización a alcanzar sus objetivos mediante el monitoreo, el control y la mejora del rendimiento de las personas, departamentos y la organización en general.",
          },
          {
            id: 2,
            header: "Los pasos a seguir para guiar el proceso",
            description:
              "Como encargado de la planeación e implementación del sistema de evaluación del desempeño de la organización, querrás seguir los pasos del siguiente checklist para guiar el proceso completo desde el inicio. De igual manera lo puedes utilizar para mejorar los procesos actuales de tu organización.",
          },
        ]}
        logo={Drawing}
      />
      <LandingFeaturedSnippet
        title={"Descubre todo lo que Sphere puede hacer por ti."}
        subtitle={"Una plataforma de RH moderna para empresas actuales."}
      />
      <Footer />
    </Container>
  );
}

export default PerformanceChecklistLandingPage;
