import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

function TripWireFeatureAlt({ title, text, logo, button }) {
  return (
    <Row className="justify-content-center align-items-center no-margin">
      <Col lg={10} md={10} className="tripWire-feature">
        <Row className="justify-content-center align-items-center">
          <Col lg={5} id="first-element">
            <h2>{title}</h2>
            <p>{text}</p>
          </Col>
          <Col lg={5} id="second-element">
            <Image fluid src={logo} />
          </Col>
        </Row>
        {button ? (
          <Col className="center">
            <Button
              className="sphere"
              size="lg"
              onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
            >
              Reserva tu prueba gratuita
            </Button>
          </Col>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
}

export default TripWireFeatureAlt;
