import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";

function LandingPageBanner({
  title,
  textA,
  textB,
  textC,
  logo,
  handleSubmitMethod,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Col className="landing-page-banner">
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={5} md={10}>
          <h1>{title}</h1>
          <p>{textA}</p>
          <p>{textB}</p>
          <p>{textC}</p>
          <Col lg={10}>
            <Form onSubmit={handleSubmit((data) => handleSubmitMethod(data))}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Nombre"
                      {...register("name", {
                        required: "Es necesario que ingreses un nombre",
                      })}
                    />
                    <p className="error">{errors.name?.message}</p>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      {...register("email", {
                        required: "Es necesario que ingreses un correo válido",
                        pattern:
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      })}
                    />
                    <p className="error">{errors.email?.message}</p>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button className="sphere" size="lg" type="submit">
                    Descargar guía
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Col>
        <Col lg={5} md={10} className="center landing-page-banner-image">
          <Image fluid src={logo} />
        </Col>
      </Row>
    </Col>
  );
}

export default LandingPageBanner;
