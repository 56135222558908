import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function RequestDemo({ title, text, buttonText, linkText }) {
  const navigate = useNavigate();

  return (
    <Col className="request-demo">
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={6} className="center">
          <h1 className="mb-4">{title}</h1>
          <h4>{text}</h4>
          <Button
            className="sphere"
            size="lg"
            onClick={() => navigate(`${linkText}`)}
          >
            {buttonText}
          </Button>
        </Col>
      </Row>
    </Col>
  );
}

export default RequestDemo;
