import React from "react";
import Container from "react-bootstrap/Container";
import NavBar from "../../Navigation/Components/NavBar";
import Footer from "../Components/Footer";
import MainBanner from "../Components/MainBanner";
import SocialProof from "../Components/SocialProof";
import FeaturedSnippet from "../Components/FeaturedSnippet";
import Testimonial from "../Components/Testimonial";
import RequestDemo from "../Components/RequestDemo";
import Feature from "../Components/Feature";
import FeatureAlt from "../Components/FeatureAlt";
import Gamma from "../../Shared/Images/gamma.png";
import DTR from "../../Shared/Images/dtr_bw.png";
import Nissin from "../../Shared/Images/nissin_bw.png";
import Joyson from "../../Shared/Images/joyson_bw.png";
import Mausoleos from "../../Shared/Images/mausoleos_bw.png";
import Taxan from "../../Shared/Images/taxan_bw.png";
import HomeBanner from "../../Shared/Images/HomePageBanner.png";
import Image1 from "../../Shared/Images/image1.png";
import Image2 from "../../Shared/Images/image2.png";
import Image3 from "../../Shared/Images/image3.png";
import Image4 from "../../Shared/Images/image4.png";
import Pilar from "../../Shared/Images/Pilar.png";

function Home() {
  return (
    <Container fluid className="no-padding">
      <NavBar />
      <MainBanner
        title={"PLATAFORMA de RH MODERNA PARA EMPRESAS MODERNAS"}
        subtitle={
          "Una herramienta probada para administrar el talento, el desempeño y el bienestar del personal."
        }
        logo={HomeBanner}
        buttonText={"Solicitar Demo"}
        linktext={"/demo"}
      />
      <SocialProof
        logo1={Taxan}
        logo2={Mausoleos}
        logo3={Nissin}
        logo4={Joyson}
        logo5={DTR}
      />
      <FeaturedSnippet
        title={"DECISIONES ESTRATÉGICAS SUSTENTADAS EN DATOS"}
        subtitle={
          "Inspirada en la cultura de la transparencia y la retroalimentación constante, Sphere utiliza las mejores prácticas para impulsar una cultura de alto desempeño y bienestar."
        }
      />

      <Feature
        title={"Basada en lo mejor"}
        subtitle={"Integra información generada por múltiples herramientas"}
        text={
          "Sphere integra una diversidad de herramientas en una misma plataforma. Nuestras herramientas, las cuales se basan en las mejores prácticas, están diseñadas especialmente para generar un verdadero impacto en los resultados de la organización."
        }
        logo={Image1}
        classText={"feature"}
      />
      <FeatureAlt
        title={"Impulsa el cambio"}
        subtitle={"Identifica las fortalezas y visualiza el desarrollo."}
        text={
          "Con Sphere podrás identificar las fortalezas y las áreas de oportunidad de cada persona que integra a la organización. Serás capaz de desarrollar planes de desarrollo para cada departamento, sucursal, personsa, etc... y podrás monitorear su evolución a través del tiempo."
        }
        logo={Image2}
        classText={"feature"}
      />
      <Feature
        title={"Retroalimentación que funciona para todos"}
        subtitle={
          "Información compartida a través de todos los niveles de la organización."
        }
        text={
          "La retroalimentación constante y frecuente es la clave para el cambio efectivo y de impacto. Con Sphere todos los niveles de la organización son partícipes de los procesos de retroalimentación que dan pie al reconocimiento y a la identificación de las áreas de oportunidad que necesitan desarrollo."
        }
        logo={Image3}
        classText={"feature-green"}
      />
      <FeatureAlt
        title={"Recursos humanos protagonista"}
        subtitle={"Impulsa el desarrollo del personal"}
        text={
          "El área de recursos humanos debe de ser uno de los pilares estratégicos de cualquier organización. Es sólo a través de su personal que las empresas son capaces de lograr sus objetivos y crecer. Con Sphere, el departamento de RH se convierten en protagonista del diseño e implementación de la estrategia de la organización."
        }
        logo={Image4}
        classText={"feature"}
      />

      <RequestDemo
        title={"¿Te gustaría conocer Sphere?"}
        text={
          "Descubre como puede ayudarte a construir una cultura de retroalimentación, desarrollo y alto desempeño."
        }
        buttonText={"Solicitar Demo"}
        linkText={"/demo"}
      />
      <Testimonial
        company={Gamma}
        profilepic={Pilar}
        text={
          "Sphere nos ha permitido establecer sistemas de evaluación de           desempeño acordes a la naturaleza del negocio y de los retos que nos hemos planteado para crear procesos que impulsen el alto    rendimiento de los líderes y equipos de trabajo"
        }
        name={"Dra. Pilar Gonzalez"}
        profile={"Directora de desarrollo organizacional - Gamma Partners"}
        title={"Dale la bienvenida a Sphere"}
        subtitle={"Plataforma moderna de RH"}
        testimonialText={"Todos los días Sphere ayuda a las empresas a fortalecer y desarrollar el compromiso, talento y desempeño de su personal"}
      />
      <Footer />
    </Container>
  );
}

export default Home;
