import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function Feature({ title, subtitle, text, logo, classText }) {
  return (
    <Col className={classText}>
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={5} md={10}>
          <h4>{subtitle}</h4>
          <h1>{title}</h1>
          <p>{text}</p>
        </Col>
        <Col lg={5} md={10} className="center">
          <Image fluid src={logo} />
        </Col>
      </Row>
    </Col>
  );
}

export default Feature;
