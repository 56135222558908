import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import NavBarEn from "../../../Navigation/Components/NavBarEn";
import FooterEn from "../../Components/FooterEn";
import MainBanner from "../../Components/MainBanner";
import SocialProof from "../../Components/SocialProof";
import Feature from "../../Components/Feature";
import TestimonialAlt from "../../Components/TestimonialAlt";
import RequestDemo from "../../Components/RequestDemo";
import TrainingBanner from "../../../Shared/Images/TrainingPageBannerEnglish.png";
import SiixSem from "../../../Shared/Images/siix_bw.png";
import Mausoleos from "../../../Shared/Images/mausoleos_bw.png";
import Joyson from "../../../Shared/Images/joyson_bw.png";
import Nissin from "../../../Shared/Images/nissin_bw.png";
import Taxan from "../../../Shared/Images/taxan_bw.png";
import TrainingDetails from "../../../Shared/Images/Capacitacion_detalle_personal_en.png";
import TrainingStatus from "../../../Shared/Images/Capacitacion_listado_status_en.png";
import TrainingMap from "../../../Shared/Images/Capacitacion_mapa_en.png";
import Fausto from "../../../Shared/Images/Fausto.png";

function TrainingEn() {
  return (
    <Container fluid className="no-padding">
      <NavBarEn />

      <MainBanner
        title={"MONITORING OF COMPLIANCE"}
        subtitle={
          "Effective and timely development plans to meet the objectives set."
        }
        logo={TrainingBanner}
        buttonText={"Request Demo"}
        linktext={"/demo/en"}
      />

      <SocialProof
        logo1={Taxan}
        logo2={Joyson}
        logo3={Nissin}
        logo4={Mausoleos}
        logo5={SiixSem}
      />

      <Col className="features">
        <Feature
          title={"Personal Kardex"}
          subtitle={"Detailed picture of each person"}
          text={
            "Know the personal status of each team member at the moment. Identify which trainings are still valid, which are pending delivery and develop the perfect plan to implement."
          }
          logo={TrainingDetails}
          classText={"feature"}
        />
      </Col>

      <TestimonialAlt
        text={
          "'Sphere helps us make immediate decisions to improve staff performance. It has many advantages over other tools due to its ease of use and its objectivity. Thanks to Sphere we are able to create development plans and generate succession plans to achieve the strategic objectives of Human Capital'"
        }
        profilepic={Fausto}
        name={"Fausto Sandoval"}
        profile={"Human resources manager"}
        company={"ESJ"}
      />

      <Col className="features">
        <Feature
          title={"Institutional Kardex"}
          subtitle={"Big picture"}
          text={
            "Know the level of workload of each person according to their job profile. Identifies the levels of compliance of each person and verifies that compliance with the development plan."
          }
          logo={TrainingStatus}
          classText={"feature"}
        />
      </Col>

      <RequestDemo
        title={"Would you like to meet Sphere?"}
        text={
          "Find out how it can help you build a culture of feedback, development and high performance."
        }
        buttonText={"Request Demo"}
        linkText={"/demo/en"}
      />

      <Col className="features">
        <Feature
          title={"Compliance map"}
          subtitle={"Identify the gaps in the plan"}
          text={
            "With Sphere you can generate a map of compliance with the staff development plan. Through this you can quickly and effectively visualize the level of compliance with the defined training plan. Identifies which trainings are pending and which have been fully complied with."
          }
          logo={TrainingMap}
          classText={"feature"}
        />
      </Col>

      <FooterEn />
    </Container>
  );
}

export default TrainingEn;
