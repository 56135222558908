import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

function TripWirePageBanner({
  title,
  subtitle,
  text,
  sendTripWireInfo,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Row className="justify-content-center align-items-center tripwire-banner-wrapper no-margin">
      <Alert variant="primary" className="mb-5">
        <strong>Muchas gracias!</strong> Revisa tu correo para descargar tu
        recurso. Puede tardar unos minutos.
      </Alert>
      <Col lg={4} md={10} className="tripwire-banner">
        <h2>{title}</h2>
        <h1>{subtitle}</h1>
        <h4>{text}</h4>
      </Col>
      <Col lg={4} md={10} className="trip-wire-form-container">
        <Form onSubmit={handleSubmit((data) => sendTripWireInfo(data))}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Nombre(s) *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa tu nombre"
              {...register("firstName", {
                required: "Es necesario que ingreses un nombre",
              })}
            />
            <p className="error">{errors.firstName?.message}</p>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Apellido(s) *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa tu apellido"
              {...register("lastName", {
                required: "Es necesario que ingreses un apellido",
              })}
            />
            <p className="error">{errors.lastName?.message}</p>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email *</Form.Label>
            <Form.Control
              type="email"
              placeholder="Ingresa tu correo electrónico"
              {...register("email", {
                required: "Es necesario que ingreses un correo válido",
                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              })}
            />
            <p className="error">{errors.email?.message}</p>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Empresa *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de tu empresa"
              {...register("company", {
                required: "Es necesario que el nombre de tu organización",
              })}
            />
            <p className="error">{errors.company?.message}</p>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Teléfono *</Form.Label>
            <Form.Control
              type="text"
              placeholder="XXX-XXX-XXXX"
              {...register("phone", {
                required: "Es necesario que ingreses un teléfono de contacto",
              })}
            />
            <p className="error">{errors.phone?.message}</p>
          </Form.Group>
          <Col className="d-grid gap-2">
            <Button variant="primary" size="lg" type="submit">
              RESERVAR
            </Button>
          </Col>
        </Form>
      </Col>
    </Row>
  );
}

export default TripWirePageBanner;
