import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import NavBarEn from "../../../Navigation/Components/NavBarEn";
import FooterEn from "../../Components/FooterEn";
import MainBanner from "../../Components/MainBanner";
import SocialProof from "../../Components/SocialProof";
import Feature from "../../Components/Feature";
import FeatureAlt from "../../Components/FeatureAlt";
import TestimonialAlt from "../../Components/TestimonialAlt";
import RequestDemo from "../../Components/RequestDemo";
import PerformanceBanner from "../../../Shared/Images/PerfomancePageBannerEnglish.png";
import PerformanceGoals from "../../../Shared/Images/Desempeno_objetivos_en.png";
import PerformanceLevels from "../../../Shared/Images/Desempeno_cumplimientos_en.png";
import PerformanceRanking from "../../../Shared/Images/Desempeno_ranking_en.png";
import Performance9Box from "../../../Shared/Images/Desempeno_9box_en.png";
import Taxan from "../../../Shared/Images/taxan_bw.png";
import Joyson from "../../../Shared/Images/joyson_bw.png";
import Nissin from "../../../Shared/Images/nissin_bw.png";
import Mausoleos from "../../../Shared/Images/mausoleos_bw.png";
import SiixSem from "../../../Shared/Images/siix_bw.png";
import Pilar from "../../../Shared/Images/Pilar.png";

function PerformanceEn() {
  return (
    <Container fluid className="no-padding">
      <NavBarEn />

      <MainBanner
        title={"CULTURE OF RESPONSIBILITY AND HIGH PERFORMANCE"}
        subtitle={
          "Manage and promote the performance of your work team."
        }
        logo={PerformanceBanner}
        buttonText={"Request Demo"}
        linktext={"/demo/en"}
      />

      <SocialProof
        logo1={SiixSem}
        logo2={Taxan}
        logo3={Nissin}
        logo4={Joyson}
        logo5={Mausoleos}
      />

      <Col className="features">
        <Feature
          title={"Made for everyone, not just HR"}
          subtitle={"Easy to implement"}
          text={
            "Quickly capture objectives and report compliance. Visualize the performance of your staff at different times and take necessary actions at the right time."
          }
          logo={PerformanceGoals}
          classText={"feature"}
        />
      </Col>

      <TestimonialAlt
        text={
          "'Sphere has allowed us to establish performance evaluation systems in accordance with the nature of the business and the challenges that we have set ourselves to create processes that promote the high performance of leaders and work teams.'"
        }
        profilepic={Pilar}
        name={"PhD. Pilar Gonzalez"}
        profile={"Organizational Development Manager"}
        company={"Gamma Partners"}
      />

      <Col className="features">
        <Feature
          title={"Easy to implement"}
          subtitle={"Versatile and adaptable"}
          text={
            "Set and manage goals for you and your team. Establish priorities and weightings according to your needs. Aligns individual objectives with those of the organization to ensure that everything is moving in the same direction."
          }
          logo={PerformanceLevels}
          classText={"feature"}
        />
      </Col>

      <RequestDemo
        title={"Would you like to get to know Sphere?"}
        text={
          "Find out how it can help you build a culture of feedback, development and high performance."
        }
        buttonText={"Request Demo"}
        linkText={"/demo/en"}
      />

      <Col className="features">
        <Feature
          title={"Identify outstanding staff"}
          subtitle={"Recognize your high performers"}
          text={
            "Know quickly and easily the average performance of your staff. Identify who is your high performance staff and who is your risk staff."
          }
          logo={PerformanceRanking}
          classText={"feature"}
        />
        <FeatureAlt
          title={"Human resources strategist"}
          subtitle={"Strategic decisions in the medium and long term"}
          text={
            "Makes the HR department a key player in the organization's strategy. Identify future leaders and rising stars and prepare them to lead the organization."
          }
          logo={Performance9Box}
          classText={"feature"}
        />
      </Col>

      <FooterEn />
    </Container>
  );
}

export default PerformanceEn;
