import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavBarEn from "../../../Navigation/Components/NavBarEn";
import FooterEn from "../../Components/FooterEn";

function PrivacyEn() {
  return (
    <Container fluid className="no-padding">
      <NavBarEn />
      <Row className="no-margin">
        <Col className="privacy">
          <Row className="justify-content-center align-items-center">
            <Col lg={10}>
              <h1>Privacy Policy</h1>

              <h2>OVERVIEW</h2>
              <p>
                At Aggero Solutions S de RL de CV, we respect your need for
                online privacy and protect any type of personal information that
                you may share with us in an appropriate manner. Our practice
                regarding the use of your personal information is as set forth
                below in this privacy policy statement. As a condition to use
                Aggero's services, you must give your consent to the terms of
                the Privacy Policy Statement and its occasional updates. This
                privacy policy statement applies exclusively to
                www.spheremodel.com/privacidad.html
              </p>

              <h2>ONLINE PRIVACY PROTECTION FOR CHILDREN</h2>
              <p>
                Aggero does not intentionally collect personal information from
                users under 13.
              </p>

              <h2>REGISTERED INFORMATION AND USE</h2>
              <h3>Personal information</h3>

              <p>
                During the registration process to create a user account, we
                request your name and email address and you will be assigned a
                password. This data will be used only for the purpose of
                facilitating access to your user account. Your name and email
                address will be used to inform you about new services, releases,
                upcoming events and changes to this Privacy Policy Statement.
              </p>

              <p>
                Aggero will have access to the personal information you provide
                as part of the use of Aggero's services, such as contacts in
                your Sphere account. We do not share this information for
                promotional purposes with anyone.
              </p>

              <p>
                We publish user testimonials on our website. These testimonials
                may include names and other personal information and we obtain
                the permission of our users before publishing this information
                on our website. Aggero is not responsible for the personal
                information that users choose to publish in their testimonies.
              </p>

              <h3>Usage details</h3>
              <p>
                We will record the usage details, such as time, frequency,
                duration and usage pattern, the functions used and the amount of
                storage used to improve the experience of Aggero's services and
                to help us provide you with the best possible service.
              </p>

              <h3>User account content</h3>
              <p>
                We store and maintain emails and other data stored in your user
                account on our servers located in the United States of America.
                To prevent data loss due to errors or system failures, we can
                also keep backup copies of the data, including the contents of
                your user account. Therefore, it is possible that the files and
                data remain on our servers, even after having deleted or closed
                your user account. We may retain and use your personal
                information and data as necessary to comply with our legal
                obligations, resolve disputes and enforce our rights. We assure
                you that the contents of your account will not be disclosed or
                access will be provided, not even to Aggero employees, except in
                the circumstances specifically mentioned in this Privacy Policy
                Statement and in the terms of service.
              </p>

              <h3>Visitor details</h3>
              <p>
                We use the internet protocol address, browser type, browser
                language, reference URL, files accessed, errors generated, time
                zone, operating system and other visitor data collected in our
                log files to analyze trends, manage the website, track visitor
                movements and improve our website. We link this automatically
                collected data with other information we collect about you.
              </p>

              <h2>COOKIES AND OTHER FOLLOW-UP TECHNOLOGIES</h2>
              <p>
                We use temporary and permanent cookies to improve the experience
                of our Aggero services. Temporary cookies will be deleted from
                your computer every time you close your browser. When you select
                the “Stay connected” option in Aggero's services, a permanent
                cookie will be stored on your computer and you will not have to
                provide the complete registration information to log in every
                time you re-enter the Sphere platform. We link cookie
                information to your email address when you choose to stay
                connected to keep and remember your preferences on the website.
              </p>

              <p>
                Aggero Solutions S de RL de CV and our partners [such as
                distributor partners], affiliates or service providers [such as
                analysis service providers] use technologies such as cookies,
                beacons, labels and scripts. These technologies are used to
                analyze trends, manage the site, track user movements through
                the site and to gather demographic information about our user
                base as a whole. It is possible that these companies send us
                reports based on the use of these technologies both individually
                and in aggregate.
              </p>

              <p>
                We use local storage objects (LSO) such as HTML5 to store
                information and content preferences. Third parties with whom we
                partner to offer certain features on our site or to display
                advertising based on your web browsing activity use LSO, such as
                HTML 5, to collect and store information. Different browsers may
                offer their own management tools to eliminate HTML5 LSOs.
              </p>

              <h3>Orientation or reorientation by behavior</h3>
              <p>
                We partner with third parties to manage our ads on other sites.
                Our external partners may use technologies such as cookies to
                collect information about their activities on this site and
                other sites in order to provide advertising based on their
                interests and exploration activities.
              </p>

              <h2>LINKS OF OUR WEBSITE</h2>
              <p>
                Some pages of our website contain links to external sites. It is
                advisable to verify the privacy practices of these websites. We
                are not responsible for the use or improper use of the
                information you provide on those websites. We encourage you not
                to provide personal information without corroborating the
                Privacy Policy Statement of other websites.
              </p>

              <h2>WITH WHOM WE SHARE THE INFORMATION</h2>
              <p>
                We may need to share your personal information and data with our
                affiliates, distributors, service providers and business
                partners with the sole purpose of providing Aggero services. It
                is possible that the purposes for which we disclose your
                personal information or data to our service providers include,
                by way of example, data storage, database management and
                analysis. These service providers are authorized to use your
                personal information or data only when necessary to provide us
                with these services. In such cases, Aggero will also ensure that
                these affiliates, distributors, service providers and business
                partners comply with this Privacy Policy Statement and adopt
                appropriate security and confidentiality measures. We will share
                your personal information with third parties only in the forms
                described in this Privacy Policy Statement. We do not sell your
                personal information to third parties. We may share generic
                aggregated demographic information not linked to any personal
                information about visitors and users with our partners and
                advertisers. Please note that it is possible that the laws in
                various jurisdictions in which we operate require us to disclose
                user information and the contents of your user account to the
                local law enforcement authorities under a legal process or
                applicable government request. In addition, we may also disclose
                personal information and the contents of your user account to
                law enforcement authorities if Aggero determines, in its sole
                discretion, that such disclosure is necessary to protect the
                safety of our users, employees or the public in general. If
                Aggero is involved in a merger, acquisition or sale of all or a
                portion of its assets or company, it will be notified by email
                or through a notice on our website, about any change in
                ownership or uses of your personal information, as well as the
                options that you may have regarding your personal information.
              </p>

              <h2>WHAT IS THE SECURITY LEVEL OF YOUR INFORMATION</h2>
              <p>
                We adopt the data collection, storage and processing practices
                and appropriate security measures of the sector, as well as
                physical security measures to provide protection against
                unauthorized access, alteration, disclosure or destruction of
                your personal information, username, password and data stored in
                your user account. Access to your name and email address is
                restricted to employees who need to know such information in
                relation to the provision of Aggero services and are governed by
                confidentiality obligations.
              </p>

              <h2>YOUR CHOICE IN THE USE OF INFORMATION</h2>
              <p>
                In the event that we decide to use your personal information for
                any purpose other than as stated in this Privacy Policy
                Statement, we will offer you an effective way to cancel your
                participation in the use of your personal information for other
                purposes. Occasionally, we may send you an email about new
                services, press releases and upcoming events. You can choose to
                stop receiving newsletters and other secondary messages from
                Aggero by selecting the “Unsubscribe” feature that includes each
                email we send. However, you will continue to receive essential
                transactional messages.
              </p>

              <h2>INVESTIGATION OF ILLEGAL ACTIVITY</h2>
              <p>
                We may need to provide access to your personal information and
                the content of your user account to our employees and service
                providers in order to investigate any alleged illegal activity
                or potential violation of the terms and conditions regarding the
                use of the services of Aggero However, Aggero will ensure that
                such access is made in accordance with this Privacy Policy
                Statement and subject to appropriate confidentiality and
                security measures.
              </p>

              <h2>IMPLEMENTATION OF THE PRIVACY POLICY</h2>
              <p>
                We make every effort, including periodic reviews, to ensure that
                the personal information you provide is used in accordance with
                this Privacy Policy Statement. If you have any concerns
                regarding our adherence to this Privacy Policy Statement or how
                personal information is used in order to provide Aggero
                services, contact customer support services at
                info@spheremodel.com . We will contact you to resolve your
                concerns and also cooperate with regulatory authorities on this
                matter if necessary.
              </p>

              <h2>NOTIFICATION OF CHANGES</h2>
              <p>
                We may modify the Privacy Policy Statement, upon notice, at any
                time through a service announcement or by sending an email to
                your primary email address. If we make important changes to the
                Privacy Policy Statement that affect your rights, a notification
                of the changes will be sent at least 30 days in advance by email
                to your main email address. You can cancel the use of Aggero
                services by email notice within 30 days after receiving
                notification of the availability of the modified Privacy Policy
                Statement, if the Privacy Policy Statement is modified in a way
                that substantially affects your rights in relation to the use of
                Aggero services. If you continue to use Aggero services after
                the effective date of any change in the Privacy Policy
                Statement, such use will be construed as a form of acceptance of
                the modification of the Privacy Policy Statement. You will not
                receive email notification of changes to the Privacy Policy
                Statement that are of minor importance. If you have concerns
                about how your personal information is used, we recommend you
                visit https://www.spheremodel.com/privacidad.html periodically.
              </p>

              <h2>BLOGS AND FORUMS</h2>
              <p>
                We offer users the possibility to publish information in blogs
                and forums to share information in a public space on the
                website. This information is publicly available to all users of
                these forums and to those who visit our website. Registration is
                required to publish information, but given the public nature of
                both platforms, it is possible that all personal information
                disclosed within these forums be used to communicate with users
                and send unsolicited messages. We encourage users to be cautious
                in the disclosure of personal information in public forums,
                since Aggero is not responsible for the personal information
                that users choose to disclose.
              </p>

              <p>
                Aggero also supports third-party widgets such as Facebook and
                Twitter buttons on the website that allow users to share
                articles and other information on different platforms. It is
                possible that these widgets collect your IP address, the page
                you visit on our site, and they may establish a cookie to allow
                the widget to function properly. These widgets do not collect or
                store any type of personal information of users on the website
                and simply act as a bridge for your convenience in the exchange
                of information. Your interactions with these widgets will be
                governed by the Privacy Policy of the company that provides
                them.
              </p>

              <h2>CONTACT US</h2>
              <p>
                If you have any questions or concerns regarding this Privacy
                Policy Statement, contact us at info@spheremodel.com. We will
                respond to all concerns within 30 days of receipt once your
                identity is determined.
              </p>

              <h2>DISCLOSURE</h2>
              <p>
                Aggero recognizes that you have the right to access your
                personal information. Aggero has no direct relationship with the
                people whose personal data it processes. If you are an
                individual user of Aggero services, you can contact Aggero
                directly to correct, modify or delete inaccurate data. In other
                cases, the person requesting access, or seeking to correct,
                modify or delete inaccurate data should direct said query to
                their data controller, who may be a customer of Aggero's
                services. If it is requested that the data be deleted, we will
                respond within a reasonable time. We will retain the personal
                information we process on behalf of our clients for as long as
                necessary to provide our clients with Aggero services. Aggero
                will retain your personal information as necessary to comply
                with our legal obligations, resolve disputes and enforce our
                agreements.
              </p>

              <p>
                Aggero may transfer personal information to external service
                providers that help us provide Aggero services. Transfers to
                subsequent third parties are covered by the provisions of this
                Privacy Policy Statement on notification and option and service
                agreements with you.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <FooterEn />
    </Container>
  );
}

export default PrivacyEn;
