import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function ScreensSummary({ title, logo }) {
  return (
    <Row className="justify-content-center align-items-center screens-summary-wrapper no-margin">
      <Col lg={8} md={10} className="screens-summary center">
        <h1>{title}</h1>
        <Image fluid src={logo} />
      </Col>
    </Row>
  );
}

export default ScreensSummary;
