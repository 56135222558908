import React from "react";
import Container from "react-bootstrap/Container";
import NavBarEn from "../../../Navigation/Components/NavBarEn";
import FooterEn from "../../Components/FooterEn";
import MainBanner from "../../Components/MainBanner";
import SocialProof from "../../Components/SocialProof";
import Feature from "../../Components/Feature";
import FeatureAlt from "../../Components/FeatureAlt";
import TestimonialAlt from "../../Components/TestimonialAlt";
import RequestDemo from "../../Components/RequestDemo";
import TalentBanner from "../../../Shared/Images/TalentPageBannerEnglish.png";
import TalentGraphs from "../../../Shared/Images/Talento_graficas_en.png";
import TalentBehaviors from "../../../Shared/Images/Talento_comportamientos_en.png";
import TalentPerspectives from "../../../Shared/Images/Talento_perspectivas_en.png";
import TalentFeedback from "../../../Shared/Images/Talento_retroalimentacion_en.png";
import TalentMap from "../../../Shared/Images/Talento_mapa_en.png";
import CTU from "../../../Shared/Images/ctu-bw.png";
import Bafar from "../../../Shared/Images/bafar-bw.png";
import Prokarma from "../../../Shared/Images/prokarma_bw.png";
import Kalisch from "../../../Shared/Images/fierroyacero_bw.png";
import GPA from "../../../Shared/Images/gpa-bw.png";
import Fausto from "../../../Shared/Images/Fausto.png";

function TalentEn() {
  return (
    <Container fluid className="no-padding">
      <NavBarEn />

      <MainBanner
        title={"Promote the growth and development of your staff"}
        subtitle={"Take your team to its best version."}
        logo={TalentBanner}
        buttonText={"Request Demo"}
        linktext={"/demo/en"}
      />

      <SocialProof
        logo1={CTU}
        logo2={Bafar}
        logo3={Kalisch}
        logo4={Prokarma}
        logo5={GPA}
      />

      <FeatureAlt
        title={"Identify areas of development"}
        subtitle={"Easy for everyone, not just HR"}
        text={
          "Does your staff have the necessary skills to carry out their position? Do you have the tools to measure and identify them? With Sphere, create action-oriented assessments. Constant feedback for managers, teams and individuals."
        }
        logo={TalentGraphs}
        classText={"feature"}
      />

      <TestimonialAlt
        text={
          "'Sphere helps us make immediate decisions to improve staff performance. It has many advantages over other tools due to its ease of use and its objectivity. Thanks to Sphere we are able to create development plans and generate succession plans to achieve the strategic objectives of Human Capital.'"
        }
        profilepic={Fausto}
        name={"Fausto Sandoval"}
        profile={"Human resources manager"}
        company={"ESJ"}
      />

      <Feature
        title={"Effective versatility"}
        subtitle={"Multiple sources of information"}
        text={
          "Most organizations are complex. Getting feedback from employees doesn't have to be. With Sphere you get feedback from different perspectives quickly, easily and effectively."
        }
        logo={TalentPerspectives}
        classText={"feature"}
      />

      <RequestDemo
        title={"Would you like to get to know Sphere?"}
        text={
          "Find out how it can help you build a culture of feedback, development and high performance."
        }
        buttonText={"Request Demo"}
        linkText={"/demo/en"}
      />

      <Feature
        title={"Skills inventory"}
        subtitle={"Identify unknown talent"}
        text={
          "With Sphere you have the tool for staff to provide honest anonymous feedback. Increase the commitment of your staff by using their experience and the dynamics of the organization to make informed decisions."
        }
        logo={TalentBehaviors}
        classText={"feature"}
      />
      <FeatureAlt
        title={"Instant reports"}
        subtitle={"Designed to take action"}
        text={
          "Sphere is designed for and by HR leaders. The platform is completely action oriented. At Sphere we believe in the power of feedback. Truly develop your staff with accurate and objective assessments."
        }
        logo={TalentMap}
        classText={"feature-green"}
      />

      <Feature
        title={"Constant feedback"}
        subtitle={"Foster a culture of high performance"}
        text={
          "Empower your people by giving them the tools to safely and consistently give and receive feedback. Create development plans based on current, reliable and real information."
        }
        logo={TalentFeedback}
        classText={"feature"}
      />

      <FooterEn />
    </Container>
  );
}

export default TalentEn;
