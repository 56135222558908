import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DemoRequestFormEn from "../../Components/DemoRequestFormEn";
import SphereLogo from "../../../Shared/Images/sphere-logo-white.png";
import DemoRequestImage from "../../../Shared/Images/Tools_en.png";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function DemoRequestEn() {
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/contacts/demo`,
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        company: data.company,
        employees: data.employees,
      },
    });
    navigate("/success");
  };

  return (
    <Container fluid className="no-padding demo-request">
      <Row className="justify-content-center align-items-center">
        <Col lg={8} sm={12} xs={12} className="demo-request-content">
          <Col className="center demo-request-logo">
            <Image src={SphereLogo} fluid />
          </Col>
          <Row className="justify-content-center align-items-center">
            <Col lg={6} sm={10} xs={10}>
              <DemoRequestFormEn handleSubmitMethod={handleSubmit} />
            </Col>
            <Col lg={6} sm={10} xs={10}>
              <h1>Request Demo</h1>
              <h4>Schedule a demo tour with someone from our team.</h4>
              <ul>
                <li>A live demo of Sphere 1 on 1</li>
                <li>Free recommendations regarding your particularities</li>
                <li>Additional resources according to your needs</li>
              </ul>
              <Image src={DemoRequestImage} fluid className="no-show" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default DemoRequestEn;
