import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavBarEn from "../../../Navigation/Components/NavBarEn";
import FooterEn from "../../Components/FooterEn";

function SuccessEn() {
  return (
    <Container fluid className="no-padding">
      <NavBarEn />
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={10} className="success center">
          <h1>Your information has been sent successfully</h1>
          <h4>We will contact you in less than 24 hours</h4>
        </Col>
      </Row>
      <FooterEn />
    </Container>
  );
}

export default SuccessEn;
