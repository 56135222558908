import React from "react";
import Container from "react-bootstrap/Container";
import Footer from "../../MainScreens/Components/Footer";
import LandingPageBanner from "../Components/LandingPageBanner";
import LandingFeature from "../../LandingPages/Components/LandingFeature";
import LandingFeaturedSnippet from "../Components/LandingFeaturedSnippet";
import Drawing from "../../Shared/Images/DemoRequestImage.png";
import Book from "../../Shared/Images/Book2.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function TalentGuideLandingPage() {

  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/contacts/landing/talent`,
      data: {
        nickName: data.name,
        email: data.email,
      },
    });
    navigate("/thankyou-guide");
  };
  return (
    <Container fluid className="no-padding">
      <LandingPageBanner
        title={
          "[GUÍA] COMO IDENTIFICAR Y DESARROLLAR EL TALENTO INTERNO DE LA ORGANIZACIÓN"
        }
        textA={
          "La administración de talento es vital para la supervivencia de una organización."
        }
        textB={
          "Hacerlo de manera efectiva le permite a la empresa ahorrar recursos, motivar al personal y generar una cultura atractiva para el talento externo."
        }
        textC={""}
        logo={Book}
        handleSubmitMethod={handleSubmit}
      />
      <LandingFeature
        title={"¿Qué hay en la guía?"}
        text={"Con esta GUÍA conocerás:"}
        bullets={[
          {
            id: 1,
            header:
              "Cómo impacta la administración de talento en la organización",
            description:
              "La administración de talento está en todas las etapas de la organización, desde los procesos de reclutamiento y selección, hasta la implementación de planes de sucesión para cuando las personas en puestos claves abandonen la organización. El contar con procesos adecuados para cada etapa significa grandes cambio de alto impacto para la empresa.",
          },
          {
            id: 2,
            header:
              "Elementos a considerar para un proceso de administración de talento",
            description:
              "Un proceso completo e integral para medir el talento de la organización se compone de diversos elementos. Es importante conocerlos dado que cada uno de ellos tiene un papel clave en el logro de los resultados esperados",
          },
          {
            id: 3,
            header: "Métodos para medir el talento",
            description:
              "Existen varios métodos para medir del talento desde distintas perspectivas. Es importante conocerlos para seleccionar el o los más adecuados para la necesidad presente en la organización.",
          },
        ]}
        logo={Drawing}
      />
      <LandingFeaturedSnippet
        title={"Descubre todo lo que Sphere puede hacer por ti."}
        subtitle={"Una plataforma de RH moderna para empresas actuales."}
      />
      <Footer />
    </Container>
  );
}

export default TalentGuideLandingPage;
