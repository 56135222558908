import React from "react";
import Container from "react-bootstrap/Container";
import NavBarEn from "../../../Navigation/Components/NavBarEn";
import FooterEn from "../../Components/FooterEn";
import MainBanner from "../../Components/MainBanner";
import SocialProof from "../../Components/SocialProof";
import FeaturedSnippet from "../../Components/FeaturedSnippet";
import Testimonial from "../../Components/Testimonial";
import RequestDemo from "../../Components/RequestDemo";
import Feature from "../../Components/Feature";
import FeatureAlt from "../../Components/FeatureAlt";
import Gamma from "../../../Shared/Images/gamma.png";
import DTR from "../../../Shared/Images/dtr_bw.png";
import Nissin from "../../../Shared/Images/nissin_bw.png";
import Joyson from "../../../Shared/Images/joyson_bw.png";
import Mausoleos from "../../../Shared/Images/mausoleos_bw.png";
import Taxan from "../../../Shared/Images/taxan_bw.png";
import HomeBanner from "../../../Shared/Images/HomePageBannerEnglish.png";
import Image1 from "../../../Shared/Images/image1.png";
import Image2 from "../../../Shared/Images/image2.png";
import Image3 from "../../../Shared/Images/image3.png";
import Image4 from "../../../Shared/Images/image4.png";
import Pilar from "../../../Shared/Images/Pilar.png";

function HomeEn() {
  return (
    <Container fluid className="no-padding">
      <NavBarEn />
      <MainBanner
        title={"MODERN HR PLATFORM FOR MODERN COMPANIES"}
        subtitle={
          "A proven tool to manage talent, performance, and well-being."
        }
        logo={HomeBanner}
        buttonText={"Request Demo"}
        linktext={"/demo/en"}
      />
      <SocialProof
        logo1={Taxan}
        logo2={Mausoleos}
        logo3={Nissin}
        logo4={Joyson}
        logo5={DTR}
      />
      <FeaturedSnippet
        title={"STRATEGIC DECISIONS SUPPORTED BY DATA"}
        subtitle={
          "Inspired by the culture of transparency and constant feedback, Sphere uses best practices to drive a culture of high performance and well-being."
        }
      />

      <Feature
        title={"Based on the best"}
        subtitle={"Integrates information generated by multiple tools"}
        text={
          "Sphere integrates a variety of tools on the same platform. Our tools, which are based on best practices, are specially designed to generate a real impact on the results of the organization."
        }
        logo={Image1}
        classText={"feature"}
      />
      <FeatureAlt
        title={"Drive change"}
        subtitle={"Identify strengths and visualize development."}
        text={
          "With Sphere you will be able to identify the strengths and areas of opportunity of each person of the organization. You will be able to develop development plans for each department, branch, person, etc... and you will be able to monitor their evolution over time."
        }
        logo={Image2}
        classText={"feature"}
      />
      <Feature
        title={"Feedback that works for everyone"}
        subtitle={"Information shared across all levels of the organization."}
        text={
          "Constant and frequent feedback is the key to effective change and impact. With Sphere, all levels of the organization are participants in the feedback processes that give rise to the recognition and identification of areas of opportunity that need development."
        }
        logo={Image3}
        classText={"feature-green"}
      />
      <FeatureAlt
        title={"Human resources protagonist"}
        subtitle={"Drive staff development"}
        text={
          "The human resources area must be one of the strategic pillars of any organization. It is only through their staff that companies are able to achieve their goals and grow. With Sphere, the HR department becomes the protagonist of the design and implementation of the organization's strategy."
        }
        logo={Image4}
        classText={"feature"}
      />

      <RequestDemo
        title={"Would you like to know more about Sphere?"}
        text={
          "Find out how it can help you build a culture of feedback, development and high performance."
        }
        buttonText={"Request Demo"}
        linkText={"/demo/en"}
      />
      <Testimonial
        company={Gamma}
        profilepic={Pilar}
        text={
          "Sphere has allowed us to establish performance evaluation systems in accordance with the nature of the business and the challenges that we have set ourselves to create processes that promote the high performance of leaders and work teams."
        }
        name={"PhD. Pilar Gonzalez"}
        profile={"Organizational Development Director - Gamma Partners"}
        title={"Say hello to Sphere"}
        subtitle={"Modern HR platform"}
        testimonialText={
          "Every day Sphere helps companies strengthen and develop the commitment, talent and performance of their people."
        }
      />
      <FooterEn />
    </Container>
  );
}

export default HomeEn;
