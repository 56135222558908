import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import NavBar from "../../Navigation/Components/NavBar";
import Footer from "../Components/Footer";
import MainBanner from "../Components/MainBanner";
import SocialProof from "../Components/SocialProof";
import Feature from "../Components/Feature";
import TestimonialAlt from "../Components/TestimonialAlt";
import RequestDemo from "../Components/RequestDemo";
import TrainingBanner from "../../Shared/Images/TrainingPageBanner.png";
import SiixSem from "../../Shared/Images/siix_bw.png";
import Mausoleos from "../../Shared/Images/mausoleos_bw.png";
import Joyson from "../../Shared/Images/joyson_bw.png";
import Nissin from "../../Shared/Images/nissin_bw.png";
import Taxan from "../../Shared/Images/taxan_bw.png";
import TrainingDetails from "../../Shared/Images/Capacitacion_detalle_personal.png";
import TrainingStatus from "../../Shared/Images/Capacitacion_listado_status.png";
import TrainingMap from "../../Shared/Images/Capacitacion_mapa.png";
import Fausto from "../../Shared/Images/Fausto.png";

function Training() {
  return (
    <Container fluid className="no-padding">
      <NavBar />

      <MainBanner
        title={"MONITOREO AL CUMPLIMIENTO"}
        subtitle={
          "Planes de desarrollo efectivos y oportunos para cumplir los objetivos planteados."
        }
        logo={TrainingBanner}
        buttonText={"Solicitar Demo"}
        linktext={"/demo"}
      />

      <SocialProof
        logo1={Taxan}
        logo2={Joyson}
        logo3={Nissin}
        logo4={Mausoleos}
        logo5={SiixSem}
      />

      <Col className="features">
        <Feature
          title={"Kardex personal"}
          subtitle={"Panorama detallado de cada persona"}
          text={
            "Conoce el status personal de cada miembro del equipo al momento. Identifica cuales capacitaciones siguen vigentes, cuales pendientes de impartir y desarrolla el plan perfecto para implementar."
          }
          logo={TrainingDetails}
          classText={"feature"}
        />
      </Col>

      <TestimonialAlt
        text={
          "'Sphere nos ayuda a tomar decisiones inmediatas para mejorar el desempeño del personal. Tiene muchas ventajas con respecto a otras herramientas por su facilidad de uso y su objetividad. Gracias a Sphere somos capaces de crear planes de desarrollo y generar planes de sucesión para lograr los objetivos estratégicos de Capital Humano'"
        }
        profilepic={Fausto}
        name={"Fausto Sandoval"}
        profile={"Gerente de Recursos Humanos"}
        company={"ESJ"}
      />

      <Col className="features">
        <Feature
          title={"Kardex institucional"}
          subtitle={"Panorama global"}
          text={
            "Conoce el nivel de carga de cada persona de acuerdo a su perfil de puesto. Identifica los niveles de cumplimiento de cada persona y verifica que los cumplimientos al plan de desarrollo. "
          }
          logo={TrainingStatus}
          classText={"feature"}
        />
      </Col>

      <RequestDemo
        title={"¿Te gustaría conocer Sphere?"}
        text={
          "Descubre como puede ayudarte a construir una cultura de retroalimentación, desarrollo y alto desempeño."
        }
        buttonText={"Solicitar Demo"}
        linkText={"/demo"}
      />

      <Col className="features">
        <Feature
          title={"Mapa de cumplimiento"}
          subtitle={"Identifica los huecos en el plan"}
          text={
            "Con Sphere puedes generar un mapa de cumplimiento al plan de desarrollo del personal. A través de este podrás visualizar de manera rápida y efectiva el nivel de cumplimiento al plan de capacitación definido. Identifica cuales capacitaciones están pendientes y cuales se han cumplido a cabalidad."
          }
          logo={TrainingMap}
          classText={"feature"}
        />
      </Col>

      <Footer />
    </Container>
  );
}

export default Training;
