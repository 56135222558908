import React from "react";
import Container from "react-bootstrap/Container";
import DTR from "../../Shared/Images/dtr_bw.png";
import Nissin from "../../Shared/Images/nissin_bw.png";
import Joyson from "../../Shared/Images/joyson_bw.png";
import Mausoleos from "../../Shared/Images/mausoleos_bw.png";
import Taxan from "../../Shared/Images/taxan_bw.png";
import ScreensImage from "../../Shared/Images/Super-Assets-Collage2.png";
import Footer from "../../MainScreens/Components/Footer";
import TripWirePageBanner from "../Components/TripWirePageBanner";
import SocialProofSnippet from "../Components/SocialProofSnippet";
import ScreensSummary from "../Components/ScreensSummary";
import TripWireFeature from "../Components/TripWireFeature";
import TripWireFeatureAlt from "../Components/TripWireFeatureAlt";
import Image1 from "../../Shared/Images/profiles-landing.png";
import Image2 from "../../Shared/Images/feedback-landing.png";
import Image3 from "../../Shared/Images/performance-landing.png";
import Image4 from "../../Shared/Images/competencies-landing.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function GuiaTripWire() {
  const navigate = useNavigate();

  const sendTripWireInfo = async (data) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/contacts/tripwire`,
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          company: data.company,
        },
      });
      navigate("/success");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container fluid className="no-padding">
      <TripWirePageBanner
        title={"RESERVA TU LUGAR!"}
        subtitle={"DEMOSTRACIÓN GRATIS"}
        text={
          "Utiliza la plataforma para evaluar el desempeño de tu equipo. Conoce como puedes implementar tu propio modelo de evaluación de talento y desempeño para tu organización."
        }
        sendTripWireInfo={sendTripWireInfo}
      />
      <SocialProofSnippet
        logo1={DTR}
        logo2={Joyson}
        logo3={Nissin}
        logo4={Mausoleos}
        logo5={Taxan}
      />
      <ScreensSummary
        title={
          "OBTENDRÁS TODO UN MODELO DE HERRAMIENTAS PARA REALIZAR UNA EVALUACIÓN PRÁCTICA, ROBUSTA Y COMPLETA DEL RENDIMIENTO DEL PERSONAL."
        }
        logo={ScreensImage}
      />
      <TripWireFeature
        title={"Definición de perfiles de puesto para evaluación"}
        text={
          "Contar con perfiles de puesto es clave para sentar las bases de la expectativa que la organización tiene sobre cada uno de los puestos. Conocerás como utilizar la plataforma para diseñar y configurar tus perfiles de puesto."
        }
        logo={Image1}
      />
      <TripWireFeatureAlt
        title={"Modelos de retroalimentación al personal"}
        text={
          "Conocerás sobre los mas recientes mecanismos para brindar retroalimentación al personal de manera efectiva, así como las herramientas necesarias para aprender a hacerlo de manera correcta y segura."
        }
        logo={Image2}
      />
      <TripWireFeature
        title={"Establecimiento de objetivos efectivos y su evaluación"}
        text={
          "Podrás llevar a cabo una evaluación de desempeño basada en objetivos a través de un proceso probado. Un método eficaz y transparente para medir el rendimiento del personal."
        }
        logo={Image3}
      />
      <TripWireFeatureAlt
        title={"Pasos para implementar una prueba piloto"}
        text={
          "Revisaremos juntos los pasos a seguir para correr una verdadera prueba piloto en tu organización para que puedas aterrizar y experimentar los beneficios de manera directa."
        }
        logo={Image4}
        button={true}
      />
      <Footer />
    </Container>
  );
}

export default GuiaTripWire;
