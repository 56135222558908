import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";

function DemoRequestFormEn({
  handleSubmitMethod,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Col className="demo-request-form">
      <Form onSubmit={handleSubmit((data) => handleSubmitMethod(data))}>
        <Form.Group className="mb-3">
          <Form.Label>* Firstname</Form.Label>
          <Form.Control
            type="text"
            {...register("firstName", {
              required: "Please write your firstname",
            })}
          />
          <p className="error">{errors.firstName?.message}</p>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>* Lastname</Form.Label>
          <Form.Control
            type="text"
            {...register("lastName", {
              required: "Please write your lastname",
            })}
          />
          <p className="error">{errors.lastName?.message}</p>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>* Email</Form.Label>
          <Form.Control
            type="email"
            {...register("email", {
              required: "A valid email is necessary",
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            })}
          />
          <p className="error">{errors.email?.message}</p>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>* Phone</Form.Label>
          <Form.Control
            type="phone"
            placeholder="XXX-XXX-XXXX"
            {...register("phone", {
              required: "A phone number is required",
            })}
          />
          <p className="error">{errors.phone?.message}</p>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>* Company</Form.Label>
          <Form.Control
            type="text"
            {...register("company", {
              required: "A company name is required",
            })}
          />
          <p className="error">{errors.company?.message}</p>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Number of employees</Form.Label>
          <Form.Select {...register("employees")}>
            <option>Select</option>
            <option value="Tier1">10-50</option>
            <option value="Tier2">51-100</option>
            <option value="Tier3">101-250</option>
            <option value="Tier4">251-500</option>
            <option value="Tier5">501-1000</option>
            <option value="Tier6">1001-5000+</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Button className="sphere" size="lg" type="submit">
            Request Demo
          </Button>
        </Form.Group>
      </Form>
    </Col>
  );
}

export default DemoRequestFormEn;
