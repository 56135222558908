import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Logo from "../../Shared/Images/sphere-logo-white.png";

function Footer() {
  return (
    <Col className="footer">
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={3} md={4}>
          <Image src={Logo} fluid className="mb-2" />
          <p>
            Sphere. Administración de talento, desempeño y bienestar del capital
            humano.
          </p>
        </Col>
        <Col lg={3} md={4}>
          <Nav defaultActiveKey="/home" className="flex-column">
            <Nav.Link href="https://spheremodel.com/blog">Blog</Nav.Link>
            <Nav.Link href="/#/privacy">Politicas de privacidad</Nav.Link>
            <Nav.Link href="https://www.facebook.com/spheremodel">
              Facebook
            </Nav.Link>
            <Nav.Link href="https://www.likedin.com/company/sphere-model">
              LinkedIn
            </Nav.Link>
            <Nav.Link href="https://www.twitter.com/SphereModel">
              Twitter
            </Nav.Link>
          </Nav>
        </Col>
        <Col lg={3} md={4}>
          <p>
            Tienes algo que decirnos? Nos encantaría escucharte. Mándanos un
            mensaje o llámanos.
          </p>
          <p>
            <strong>Teléfono</strong>: +52 (614) 274-9100 <br />
            <strong>Email</strong>: info@spheremodel.com
          </p>
        </Col>
      </Row>
    </Col>
  );
}

export default Footer;
