import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function FeatureAlt({ title, subtitle, text, logo, classText }) {
  return (
    <Col className={classText}>
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={5} md={10} id="first-element" className="center">
          <Image fluid src={logo} />
        </Col>
        <Col lg={5} md={10} className="right" id="second-element">
          <h4>{subtitle}</h4>
          <h1>{title}</h1>
          <p>{text}</p>
        </Col>
      </Row>
    </Col>
  );
}

export default FeatureAlt;
