import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function TestimonialAlt({ text, profilepic, name, profile, company }) {
  return (
    <Col className="testimonial-alt center">
      <Row className="justify-content-center my-5 no-margin">
        <Col lg={6} md={10}>
          <Col className="center">
            <Image fluid src={profilepic} />
          </Col>
          <Col>
            <h6>{name}</h6>
            <p>
              {profile} - {company}
            </p>
            <p>
              <i>{text}</i>
            </p>
          </Col>
        </Col>
      </Row>
    </Col>
  );
}

export default TestimonialAlt;
