import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavBar from "../../Navigation/Components/NavBar";
import Footer from "../Components/Footer";

function Success() {
  return (
    <Container fluid className="no-padding">
      <NavBar />
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={10} className="success center">
          <h1>Tu información ha sido enviada exitosamente</h1>
          <h4>Nos pondremos en contacto contigo en menos de 24 horas</h4>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}

export default Success;
