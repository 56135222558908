import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./MainScreens/Pages/Home";
import Talent from "./MainScreens/Pages/Talent";
import Performance from "./MainScreens/Pages/Performance";
import Wellness from "./MainScreens/Pages/Wellness";
import Training from "./MainScreens/Pages/Training";
import Privacy from "./MainScreens/Pages/Privacy";
import Demo from "./MainScreens/Pages/DemoRequest";
import TalentLanding from "./LandingPages/Pages/TalentGuideLandingPage";
import GuiaTripWire from "./TripWirePages/Pages/GuiaTripWire";
import ChecklistTripWire from "./TripWirePages/Pages/ChecklistTripWire";
import CompensationLanding from "./LandingPages/Pages/CompensationLandingPage";
import FeedbackLanding from "./LandingPages/Pages/FeedbackLandingPage";
import PerformanceChecklistLanding from "./LandingPages/Pages/PerformanceChecklistLandingPage";
import Success from "./MainScreens/Pages/Success";
import TagManager from "react-gtm-module";
import HomeEn from "./MainScreens/Pages/en/HomeEn";
import DemoRequestEn from "./MainScreens/Pages/en/DemoRequestEn";
import PerformanceEn from "./MainScreens/Pages/en/PerformanceEn";
import PrivacyEn from "./MainScreens/Pages/en/PrivacyEn";
import SuccessEn from "./MainScreens/Pages/en/SuccessEn";
import TalentEn from "./MainScreens/Pages/en/TalentEn";
import TrainingEn from "./MainScreens/Pages/en/TrainingEn";
import WellnessEn from "./MainScreens/Pages/en/WellnessEn";

function App() {
  const tagManagerArgs = {
    gtmId: "GTM-5L8LSVD",
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/talent" element={<Talent />}></Route>
      <Route path="/performance" element={<Performance />}></Route>
      <Route path="/wellness" element={<Wellness />}></Route>
      <Route path="/training" element={<Training />}></Route>
      <Route path="/privacy" element={<Privacy />}></Route>
      <Route path="/demo" element={<Demo />}></Route>
      <Route path="/talent-landing" element={<TalentLanding />}></Route>
      <Route path="/compensation-landing" element={<CompensationLanding />}></Route>
      <Route path="/feedback-landing" element={<FeedbackLanding />}></Route>
      <Route path="/performance-checklist-landing" element={<PerformanceChecklistLanding />}></Route>
      <Route path="/thankyou-guide" element={<GuiaTripWire />}></Route>
      <Route path="/thankyou-checklist" element={<ChecklistTripWire />}></Route>
      <Route path="/success" element={<Success />}></Route>
      <Route path="/en" element={<HomeEn />}></Route>
      <Route path="/talent/en" element={<TalentEn />}></Route>
      <Route path="/performance/en" element={<PerformanceEn />}></Route>
      <Route path="/wellness/en" element={<WellnessEn />}></Route>
      <Route path="/training/en" element={<TrainingEn />}></Route>
      <Route path="/privacy/en" element={<PrivacyEn />}></Route>
      <Route path="/demo/en" element={<DemoRequestEn />}></Route>
      <Route path="/success/en" element={<SuccessEn />}></Route>
    </Routes>
  );
}

export default App;
