import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import NavBarEn from "../../../Navigation/Components/NavBarEn";
import FooterEn from "../../Components/FooterEn";
import MainBanner from "../../Components/MainBanner";
import SocialProof from "../../Components/SocialProof";
import Feature from "../../Components/Feature";
import TestimonialAlt from "../../Components/TestimonialAlt";
import RequestDemo from "../../Components/RequestDemo";
import WellnessBanner from "../../../Shared/Images/WellnessPageBannerEnglish.png";
import WellnessHorzBars from "../../../Shared/Images/Bienestar_barras_horz_en.png";
import WellnessCloudMap from "../../../Shared/Images/Bienestar_abiertas_en.png";
import WellnessMap from "../../../Shared/Images/Bienestar_mapa_en.png";
import Coparmex from "../../../Shared/Images/Coparmex_bw.png";
import ProKarma from "../../../Shared/Images/prokarma_bw.png";
import LAM from "../../../Shared/Images/SistemaLAM_bw.png";
import Soles from "../../../Shared/Images/Soles_bw.png";
import SiixSem from "../../../Shared/Images/siix_bw.png";
import Gamboa from "../../../Shared/Images/Gamboa.png";

function WellnessEn() {
  return (
    <Container fluid className="no-padding">
      <NavBarEn />

      <MainBanner
        title={"Happy employees achieve amazing things."}
        subtitle={
          "Anonymous feedback key to taking initiatives with confidence"
        }
        logo={WellnessBanner}
        buttonText={"Request Demo"}
        linktext={"/demo/en"}
      />

      <SocialProof
        logo1={LAM}
        logo2={Soles}
        logo3={SiixSem}
        logo4={Coparmex}
        logo5={ProKarma}
      />

      <Col className="features">
        <Feature
          title={"Secure and anonymous process"}
          subtitle={"Ask the right questions"}
          text={
            "Select from our catalog of questions and quizzes designed by experts. Use them as they are or adapt them according to your needs."
          }
          logo={WellnessHorzBars}
          classText={"feature"}
        />
      </Col>

      <TestimonialAlt
        text={
          "'Sphere was an integral and very complete support for the strengthening of the operational team, resulting in clarity for the decisions we made and the path to follow for this project in which the growth of the company is reflected. Sphere allowed us to develop the team of leaders that transcend.'"
        }
        profilepic={Gamboa}
        name={"Lic. Luis Gamboa"}
        profile={"Human capital manager"}
        company={"Kalischatarra"}
      />

      <Col className="features">
        <Feature
          title={"Adhoc customization"}
          subtitle={"Understand the current dynamics"}
          text={
            "Sphere helps you identify the areas with the greatest opportunity. Immediate, easy-to-interpret reports to transform results into change actions."
          }
          logo={WellnessCloudMap}
          classText={"feature"}
        />
      </Col>

      <RequestDemo
        title={"Would you like to get to know Sphere?"}
        text={
          "Find out how it can help you build a culture of feedback, development and high performance."
        }
        buttonText={"Request Demo"}
        linkText={"/demo/en"}
      />

      <Col className="features">
        <Feature
          title={"Take advantage of the information to understand your staff"}
          subtitle={"Feedback-based culture"}
          text={
            "Managers and teams have the information they need to find focus and create plans based on the true feelings and thoughts of their people."
          }
          logo={WellnessMap}
          classText={"feature"}
        />
      </Col>

      <FooterEn />
    </Container>
  );
}

export default WellnessEn;
