import React from "react";
import Container from "react-bootstrap/Container";
import Footer from "../../MainScreens/Components/Footer";
import LandingPageBanner from "../Components/LandingPageBanner";
import LandingFeature from "../../LandingPages/Components/LandingFeature";
import LandingFeaturedSnippet from "../Components/LandingFeaturedSnippet";
import Drawing from "../../Shared/Images/DemoRequestImage.png";
import Book from "../../Shared/Images/feedback-landing.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function FeedbackLandingPage() {

  const navigate = useNavigate();

  const handleSubmit = async (data) => {
     await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/contacts/landing/feedback`,
      data: {
        nickName: data.name,
        email: data.email
      }
    });
    navigate("/thankyou-guide");
  }
  return (
    <Container fluid className="no-padding">
      <LandingPageBanner
        title={
          "[GUÍA EXPRESS] Como brindar retroalimentación de manera efectiva."
        }
        textA={
          "Dar retroalimentación puede ser un desafío, especialmente cuando se tiene algo crítico que decir."
        }
        textB={
          "¿Con cuanta frecuencia te encuentras en el dilema de decir algo o dejarlo pasar?"
        }
        textC={""}
        logo={Book}
        handleSubmitMethod={handleSubmit}
      />
      <LandingFeature
        title={"¿Qué hay en la guía?"}
        text={"Con esta GUÍA conocerás:"}
        bullets={[
          {
            id: 1,
            header:
              "La fórmula para brindar retroalimentación de forma correcta",
            description:
              "Existe una formula básica para brindar retroalimentación. Está fórmula esta diseñada para transmitir tu mensaje e incrementa las probabilidades de que tu retroalimentación sea aceptada. Puedes usar esta fórmula para brindar retroalimentación positiva y constructiva.",
          },
          {
            id: 2,
            header: "Tips sobre que elementos clave",
            description:
              "Existen algunos tips clave que ayudan a que la retroalimentación compartida tenga un verdadero impacto y no sea ignorada. Conocerás un par de ellos para que puedas utilizarlos a la hora de brindar retroalimentación a tu equipo.",
          },
        ]}
        logo={Drawing}
      />
      <LandingFeaturedSnippet
        title={"Descubre todo lo que Sphere puede hacer por ti."}
        subtitle={"Una plataforma de RH moderna para empresas actuales."}
      />
      <Footer />
    </Container>
  );
}

export default FeedbackLandingPage;
