import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import NavBar from "../../Navigation/Components/NavBar";
import Footer from "../Components/Footer";
import MainBanner from "../Components/MainBanner";
import SocialProof from "../Components/SocialProof";
import Feature from "../Components/Feature";
import FeatureAlt from "../Components/FeatureAlt";
import TestimonialAlt from "../Components/TestimonialAlt";
import RequestDemo from "../Components/RequestDemo";
import PerformanceBanner from "../../Shared/Images/PerfomancePageBanner.png";
import PerformanceGoals from "../../Shared/Images/Desempeno_objetivos.png";
import PerformanceLevels from "../../Shared/Images/Desempeno_cumplimientos.png";
import PerformanceRanking from "../../Shared/Images/Desempeno_ranking.png";
import Performance9Box from "../../Shared/Images/Desempeno_9box.png";
import Taxan from "../../Shared/Images/taxan_bw.png";
import Joyson from "../../Shared/Images/joyson_bw.png";
import Nissin from "../../Shared/Images/nissin_bw.png";
import Mausoleos from "../../Shared/Images/mausoleos_bw.png";
import SiixSem from "../../Shared/Images/siix_bw.png";
import Pilar from "../../Shared/Images/Pilar.png";

function Performance() {
  return (
    <Container fluid className="no-padding">
      <NavBar />

      <MainBanner
        title={"CULTURA DE RESPONSABILIDAD Y ALTO DESEMPEÑO"}
        subtitle={
          "Administra y fomenta el rendimiento de tus equipo de trabajo."
        }
        logo={PerformanceBanner}
        buttonText={"Solicitar Demo"}
        linktext={"/demo"}
      />

      <SocialProof
        logo1={SiixSem}
        logo2={Taxan}
        logo3={Nissin}
        logo4={Joyson}
        logo5={Mausoleos}
      />

      <Col className="features">
        <Feature
          title={"Hecho para todos, no solo para RH"}
          subtitle={"Fácil de implementar"}
          text={
            "Rápidamente captura objetivos y reporta cumplimientos. Visualiza el rendimiento de tu personal en diferentes momentos y toma acciones necesarias en el momento correcto."
          }
          logo={PerformanceGoals}
          classText={"feature"}
        />
      </Col>

      <TestimonialAlt
        text={
          "'Sphere nos ha permitido establecer sistemas de evaluación de desempeño acordes a la naturaleza del negocio y de los retos que nos hemos planteado para crear procesos que impulsen el alto rendimiento de los líderes y equipos de trabajo.'"
        }
        profilepic={Pilar}
        name={"Dr. Pilar Gonzalez"}
        profile={"Gerente de desarrollo organizacional"}
        company={"Gamma Partners"}
      />

      <Col className="features">
        <Feature
          title={"Fácil de implementar"}
          subtitle={"Versatil y adaptable"}
          text={
            "Establece y administra objetivos para ti y tu equipo. Establece prioridades y ponderaciones de acuerdo a tus necesidades. Alinea objetivos individuales con los de la organización para asegurarse que todo marcha hacia la misma dirección."
          }
          logo={PerformanceLevels}
          classText={"feature"}
        />
      </Col>

      <RequestDemo
        title={"¿Te gustaría conocer Sphere?"}
        text={
          "Descubre como puede ayudarte a construir una cultura de retroalimentación, desarrollo y alto desempeño."
        }
        buttonText={"Solicitar Demo"}
        linkText={"/demo"}
      />

      <Col className="features">
        <Feature
          title={"Identifica al personal destacado"}
          subtitle={"Reconoce a tu personal de alto rendimiento"}
          text={
            "Conoce rápida y fácilmente el desempeño promedio de tu personal. Identifica quién es tu personal de alto desempeño y quién es tu personal de riesgo."
          }
          logo={PerformanceRanking}
          classText={"feature"}
        />
        <FeatureAlt
          title={"Recuros humanos estratega"}
          subtitle={"Decisiones estratégicas a mediano y largo plazo"}
          text={
            "Convierte al departamento de RH en un jugador clave de la estrategia de la organización. Identifica a los futuros líderes y estrellas emergentes y prepáralos para liderar a la organización."
          }
          logo={Performance9Box}
          classText={"feature"}
        />
      </Col>

      <Footer />
    </Container>
  );
}

export default Performance;
