import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function FeaturedSnippet({ title, subtitle }) {
  return (
    <Col className="unique-value">
      <Row className="justify-content-center align-items-center center no-margin">
        <Col lg={8}>
          <h1 className="mb-4">{title}</h1>
          <h4>{subtitle}</h4>
        </Col>
      </Row>
    </Col>
  );
}

export default FeaturedSnippet;
