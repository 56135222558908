import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function TripWireFeature({ title, text, logo }) {
  return (
    <Row className="justify-content-center align-items-center no-margin">
      <Col lg={10} md={10} className="tripWire-feature">
        <Row className="justify-content-center align-items-center">
          <Col lg={5}>
            <Image fluid src={logo} />
          </Col>
          <Col lg={5}>
            <h2>{title}</h2>
            <p>{text}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default TripWireFeature;
