import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function SocialProofSnippet({ logo1, logo2, logo3, logo4, logo5 }) {
  return (
    <Col className="social-proof-snippet">
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={2} md={3}>
          <Image fluid src={logo1} />
        </Col>
        <Col lg={2} md={3}>
          <Image fluid src={logo2} />
        </Col>
        <Col lg={2} md={3}>
          <Image fluid src={logo3} />
        </Col>
        <Col lg={2} md={3}>
          <Image fluid src={logo4} />
        </Col>
        <Col lg={2}>
          <Image fluid src={logo5} className="no-show-image"/>
        </Col>
      </Row>
    </Col>
  );
}

export default SocialProofSnippet;
