import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavBar from "../../Navigation/Components/NavBar";
import Footer from "../Components/Footer";

function Privacy() {
  return (
    <Container fluid className="no-padding">
      <NavBar />
      <Row className="no-margin">
        <Col className="privacy">
          <Row className="justify-content-center align-items-center">
            <Col lg={10}>
              <h1>POLÍTICA DE PRIVACIDAD</h1>

              <h2>GENERAL</h2>
              <p>
                En Aggero Solutions S de RL de CV, respetamos su necesidad de
                contar con privacidad en línea y proteger cualquier tipo de
                información personal que posiblemente comparta con nosotros de
                una manera adecuada. Nuestra práctica con respecto al uso de su
                información personal es como se establece a continuación en esta
                declaración de política de privacidad. Como condición para
                utilizar los servicios de Aggero, usted debe dar su
                consentimiento a los términos de la Declaración de Política de
                privacidad y a sus ocasionales actualizaciones. Esta declaración
                de política de privacidad se aplica exclusivamente en
                www.spheremodel.com/privacidad.html
              </p>

              <h2>PROTECCIÓN DE PRIVACIDAD EN LÍNEA PARA NIÑOS</h2>
              <p>
                Aggero no recopila intencionadamente información personal de
                usuarios menores de 13 años.
              </p>

              <h2>INFORMACIÓN REGISTRADA Y USO</h2>
              <h3>Información personal</h3>
              <p>
                Durante el proceso de registro para crear una cuenta de usuario,
                solicitamos su nombre y dirección de correo electrónico y se le
                asignará una contraseña. Estos datos se utilizarán únicamente
                con el fin de facilitar el acceso a su cuenta de usuario. Su
                nombre y dirección de correo electrónico se utilizarán para
                informarle sobre nuevos servicios, lanzamientos, próximos
                eventos y cambios en esta Declaración de Política de privacidad.
              </p>

              <p>
                Aggero tendrá acceso a la información personal que usted
                proporcione como parte del uso de los servicios de Aggero, tales
                como contactos en su cuenta de Sphere. No compartimos esta
                información para fines promocionales con nadie.{" "}
              </p>

              <p>
                Publicamos los testimonios de usuarios en nuestro sitio web.
                Estos testimonios pueden incluir nombres y otro tipo de
                información personal y obtenemos el permiso de nuestros usuarios
                antes de publicar esta información en nuestra página web. Aggero
                no se hace responsable de la información personal que los
                usuarios eligen publicar en sus testimonios.
              </p>

              <h3>Detalles de uso</h3>
              <p>
                Registraremos los detalles de uso, tales como la hora, la
                frecuencia, la duración y el patrón de uso, las funciones
                utilizadas y la cantidad de almacenamiento utilizado para
                mejorar la experiencia de los servicios de Aggero y para
                ayudarnos a proporcionarle el mejor servicio posible.
              </p>

              <h3>Contenido de la cuenta de usuario</h3>
              <p>
                Almacenamos y mantenemos correos electrónicos y otros datos
                almacenados en su cuenta de usuario en nuestros servidores
                ubicados en los Estados Unidos de América. Para evitar la
                pérdida de datos a causa de errores o fallas del sistema,
                también podemos mantener copias de seguridad de los datos,
                incluido el contenido de su cuenta de usuario. Por lo tanto, es
                posible que los archivos y datos permanezcan en nuestros
                servidores, incluso después de haber eliminado o cerrado su
                cuenta de usuario. Es posible que retengamos y utilicemos su
                información personal y los datos según sea necesario para
                cumplir con nuestras obligaciones legales, resolver disputas y
                hacer cumplir nuestros derechos. Le aseguramos que no se
                divulgará el contenido de su cuenta ni se brindará acceso a
                este, ni siquiera a los empleados de Aggero, excepto en las
                circunstancias mencionadas específicamente en esta Declaración
                de Política de privacidad y en los términos de servicio.
              </p>

              <h3>Detalles del visitante</h3>
              <p>
                Utilizamos la dirección de protocolo de internet, el tipo de
                navegador, el idioma del navegador, la URL de referencia, los
                archivos a los que se accedió, los errores generados, la zona
                horaria, el sistema operativo y otros datos de los visitantes
                recopilados en nuestros archivos de registro para analizar
                tendencias, administrar el sitio web, rastrear los movimientos
                del visitante y mejorar nuestro sitio web. Vinculamos estos
                datos recopilados automáticamente con otro tipo de información
                que recopilamos sobre usted.
              </p>

              <h2>COOKIES Y OTRAS TECNOLOGÍAS DE SEGUIMIENTO</h2>
              <p>
                Utilizamos cookies temporales y permanentes para mejorar la
                experiencia de nuestros servicios de Aggero. Las cookies
                temporales se eliminarán de su computadora cada vez que cierre
                el navegador. Al seleccionar la opción “Seguir conectado” en los
                servicios de Aggero, una cookie permanente se almacenará en su
                computadora y no deberá proporcionar la información de registro
                completa para iniciar sesión cada vez que vuelve a ingresar a la
                plataforma Sphere. Enlazamos información de cookies a su
                dirección de correo electrónico cuando elige seguir conectado
                para mantener y recordar sus preferencias en el sitio web.
              </p>

              <p>
                Aggero Solutions S de RL de CV y nuestros socios [tales como
                socios distribuidores], afiliados o proveedores de servicios
                [tales como proveedores de servicios de análisis] utilizan
                tecnologías, tales como cookies, balizas, etiquetas y scripts.
                Estas tecnologías se utilizan para analizar tendencias,
                administrar el sitio, realizar un seguimiento de los movimientos
                de los usuarios por el sitio y para reunir información
                demográfica sobre nuestra base de usuarios como un todo. Es
                posible que estas empresas nos hagan llegar informes basados en
                el uso de estas tecnologías tanto de forma individual como
                agregada.
              </p>

              <p>
                Usamos objetos de almacenamiento local (LSO) como HTML5 para
                almacenar información y preferencias de contenido. Los terceros
                con quienes nos asociamos para ofrecer ciertas funciones en
                nuestro sitio o para mostrar publicidad basada en su actividad
                de navegación web utilizan LSO, tales como HTML 5, para
                recopilar y almacenar información. Es posible que distintos
                exploradores ofrezcan sus propias herramientas de gestión para
                eliminar los LSO HTML5.
              </p>

              <p>
                Nos asociamos con terceros para gestionar nuestros anuncios en
                otros sitios. Es posible que nuestros socios externos utilicen
                tecnologías como las cookies para recopilar información acerca
                de sus actividades en este sitio y otros sitios con el fin de
                proporcionarle publicidad basada en sus intereses y actividades
                de exploración.
              </p>

              <h2>ENLACES DE NUESTRO SITIO WEB</h2>
              <p>
                Algunas páginas de nuestro sitio web contienen enlaces a sitios
                externos. Se aconseja verificar las prácticas de privacidad de
                esos sitios web. No somos responsables por la forma de uso o el
                uso incorrecto de la información que proporciona en esos sitios
                web. Lo alentamos a no proporcionar información personal sin
                corroborar por sí mismo la Declaración de Política de privacidad
                de otros sitios web.
              </p>

              <h2>CON QUIÉN COMPARTIMOS LA INFORMACIÓN</h2>
              <p>
                Es posible que debamos compartir su información personal y sus
                datos con nuestros afiliados, distribuidores, proveedores de
                servicio y socios empresariales con el único propósito de
                brindarle los servicios de Aggero. Es posible que los fines para
                los cuales divulgamos su información personal o datos a nuestros
                proveedores de servicio incluyan, a modo enunciativo, el
                almacenamiento de datos, la gestión de bases de datos y el
                análisis. Estos proveedores de servicio están autorizados a
                utilizar su información personal o datos únicamente cuando sea
                necesario para proporcionarnos estos servicios. En dichos casos,
                Aggero también se asegurará de que estos afiliados,
                distribuidores, proveedores de servicio y socios empresariales
                cumplan con esta Declaración de Política de privacidad y adopten
                las medidas de seguridad y confidencialidad adecuadas.
                Compartiremos su información personal con terceros únicamente en
                las formas que se describen en esta Declaración de Política de
                privacidad. No vendemos su información personal a terceros. Es
                posible que compartamos la información demográfica agregada
                genérica no vinculada a ningún tipo de información personal
                sobre los visitantes y usuarios con nuestros socios y
                anunciantes. Tenga en cuenta que es posible que las leyes en
                diversas jurisdicciones en las cuales operamos nos obliguen a
                revelar la información del usuario y el contenido de su cuenta
                de usuario a las autoridades locales encargadas del cumplimiento
                de la ley en virtud de un proceso legal o una solicitud
                gubernamental aplicable. Además, es posible que también
                divulguemos la información personal y el contenido de su cuenta
                de usuario a las autoridades encargadas del cumplimiento de la
                ley si Aggero determina, a su criterio, que tal divulgación es
                necesaria para proteger la seguridad de nuestros usuarios,
                empleados o el público en general. Si Aggero está involucrada en
                una fusión, adquisición o venta de la totalidad o de una parte
                de sus activos o empresa, se lo notificará por correo
                electrónico o por medio de un aviso destacado en nuestro sitio
                web, sobre cualquier cambio en la titularidad o los usos de su
                información personal, así como las opciones que es posible que
                tenga con respecto a su información personal.
              </p>

              <h2>CUÁL ES EL NIVEL DE SEGURIDAD DE SU INFORMACIÓN</h2>
              <p>
                Adoptamos la recopilación de datos, las prácticas de
                almacenamiento y procesamiento y las medidas de seguridad
                apropiadas del sector, así como las medidas de seguridad física
                para brindar protección contra el acceso no autorizado, la
                alteración, la divulgación o la destrucción de su información
                personal, nombre de usuario, contraseña y datos almacenados en
                su cuenta de usuario. El acceso a su nombre y dirección de
                correo electrónico está restringido a los empleados que
                necesitan conocer dicha información en relación con la
                prestación de los servicios de Aggero, y están regidos por las
                obligaciones de confidencialidad.
              </p>

              <h2>SU ELECCIÓN EN EL USO DE LA INFORMACIÓN</h2>
              <p>
                En el caso de que decidamos utilizar su información personal
                para cualquier otro fin que no sea como se indica en esta
                Declaración de Política de privacidad, le ofreceremos una manera
                efectiva para cancelar su participación en el uso de su
                información personal para otros fines. Ocasionalmente, es
                posible que le enviemos un correo electrónico sobre nuevos
                servicios, comunicados de prensa y próximos eventos. Puede optar
                por dejar de recibir boletines y otros mensajes secundarios de
                Aggero seleccionando la función “Cancelar suscripción” que
                incluye cada correo electrónico que enviamos. No obstante,
                continuará recibiendo mensajes transaccionales esenciales.
              </p>

              <h2>INVESTIGACIÓN DE ACTIVIDAD ILEGAL</h2>
              <p>
                Es posible que debamos proporcionar acceso a su información
                personal y al contenido de su cuenta de usuario a nuestros
                empleados y proveedores de servicios con el fin de investigar
                cualquier presunta actividad ilegal o potencial infracción de
                los términos y condiciones con respecto al uso de los servicios
                de Aggero. No obstante, Aggero se asegurará de que dicho acceso
                se realice de conformidad con esta Declaración de Política de
                privacidad y sujeto a las medidas de confidencialidad y
                seguridad adecuadas.
              </p>

              <h2>IMPLEMENTACIÓN DE LA POLÍTICA DE PRIVACIDAD</h2>
              <p>
                Hacemos todos los esfuerzos posibles, incluidos exámenes
                periódicos para asegurarnos de que la información personal que
                proporciona se utilice de conformidad con esta Declaración de
                Política de privacidad. Si tiene alguna inquietud con respecto a
                nuestra adhesión a esta Declaración de Política de privacidad o
                la forma en que se utiliza la información personal con el fin de
                prestar los servicios de Aggero, comuníquese con los servicios
                de asistencia al cliente en info@spheremodel.com. Nos
                comunicaremos con usted para resolver sus inquietudes y también
                cooperaremos con las autoridades reguladoras sobre este asunto
                si es necesario.
              </p>

              <h2>NOTIFICACIÓN DE CAMBIOS</h2>
              <p>
                Es posible que modifiquemos la Declaración de la Política de
                privacidad, previa notificación, en cualquier momento a través
                de un anuncio de servicio o enviando un correo electrónico a su
                dirección de correo electrónico principal. Si hacemos cambios
                importantes en la Declaración de la Política de privacidad que
                afecten sus derechos, se enviará una notificación de los cambios
                con un plazo de antelación mínimo de 30 días por correo
                electrónico a su dirección de correo electrónico principal.
                Puede cancelar el uso de los servicios de Aggero mediante aviso
                por correo electrónico dentro de un plazo de 30 días después de
                haber recibido la notificación de la disponibilidad de la
                Declaración de la Política de privacidad modificada, si la
                Declaración de la Política de privacidad se modifica de una
                manera que afecta sustancialmente sus derechos en relación con
                el uso de los servicios de Aggero. Si continúa usando los
                servicios de Aggero después de la fecha de entrada en vigencia
                de cualquier cambio en la Declaración de la Política de
                privacidad, tal uso se interpretará como una forma de aceptación
                de la modificación de la Declaración de Política de privacidad.
                No recibirá por correo electrónico la notificación de los
                cambios a la Declaración de Política de privacidad que sean de
                menor importancia. Si tiene inquietudes sobre cómo se usa su
                información personal, le recomendamos ingresar a
                https://www.spheremodel.com/privacidad.html periódicamente.
              </p>

              <h2>BLOGS Y FOROS</h2>
              <p>
                Les ofrecemos a los usuarios la posibilidad de publicar
                información en blogs y foros para compartir información en un
                espacio público en el sitio web. Esta información está
                públicamente disponible para todos los usuarios de estos foros y
                para quienes visitan nuestro sitio web. Es necesario registrarse
                para publicar información, pero dado el carácter público de
                ambas plataformas, es posible que toda la información personal
                que se divulgue dentro de estos foros se utilice para
                comunicarse con los usuarios y enviarles mensajes no
                solicitados. Alentamos a los usuarios a ser cautelosos en la
                divulgación de información personal en foros públicos, ya que
                Aggero no se hace responsable de la información personal que los
                usuarios eligen revelar.
              </p>

              <p>
                Aggero también admite widgets de terceros tales como botones de
                Facebook y Twitter en el sitio web que permiten a los usuarios
                compartir artículos y otra información en diferentes
                plataformas. Es posible que estos widgets recopilen su dirección
                IP, la página que visita en nuestro sitio, y es posible que
                establezcan una cookie para permitir el correcto funcionamiento
                de los widgets. Estos widgets no recopilan ni almacenan ningún
                tipo de información personal de los usuarios en el sitio web y
                simplemente actúan como un puente para su comodidad en el
                intercambio de información. Sus interacciones con estos widgets
                se regirán por la Política de privacidad de la empresa que los
                proporciona.
              </p>

              <h2>CONTÁCTENOS</h2>
              <p>
                Si tiene alguna pregunta o inquietud con respecto a esta
                Declaración de Política de privacidad, comuníquese con nosotros
                a info@spheremodel.com. Responderemos a todas las inquietudes
                dentro de un plazo de 30 días desde la recepción una vez que se
                determine su identidad.
              </p>

              <h2>DIVULGACIÓN </h2>
              <p>
                Aggero reconoce que usted tiene derecho a acceder a su
                información personal. Aggero no tiene relación directa con las
                personas cuyos datos personales procesa. Si usted es un usuario
                individual de los servicios de Aggero, puede comunicarse
                directamente con Aggero para corregir, modificar o eliminar los
                datos inexactos. En otros casos, la persona que solicita el
                acceso, o que busca corregir, modificar o eliminar los datos
                inexactos debe dirigir dicha consulta a su controlador de datos,
                que puede ser un cliente de los servicios de Aggero. Si se
                solicita que se eliminen los datos, responderemos dentro de un
                plazo razonable. Conservaremos la información personal que
                procesamos en nombre de nuestros clientes por tanto tiempo como
                sea necesario para prestar a nuestros clientes los servicios de
                Aggero. Aggero conservará su información personal según sea
                necesario para cumplir con nuestras obligaciones legales,
                resolver disputas y hacer cumplir nuestros acuerdos.
              </p>

              <p>
                Aggero puede transferir información personal a los proveedores
                de servicios externos que nos ayudan a prestar los servicios de
                Aggero. Las transferencias a terceros subsiguientes están
                cubiertas por las disposiciones de esta Declaración de Política
                de privacidad sobre notificación y opción y los acuerdos de
                servicio con usted.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}

export default Privacy;
