import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function LandingFeature({ title, bullets, text, logo }) {
  return (
    <Col className="landing-feature">
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={5} md={10}>
          <Image fluid src={logo} />
        </Col>
        <Col lg={5} md={10}>
          <h2>{title}</h2>
          <p>{text}</p>
          <ul>
            {bullets.map((item) => (
              <li key={item.id}>
                <h5>{item.header}</h5>
                <p>{item.description}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Col>
  );
}

export default LandingFeature;
