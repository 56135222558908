import React from "react";
import Container from "react-bootstrap/Container";
import Footer from "../../MainScreens/Components/Footer";
import LandingPageBanner from "../Components/LandingPageBanner";
import LandingFeature from "../../LandingPages/Components/LandingFeature";
import LandingFeaturedSnippet from "../Components/LandingFeaturedSnippet";
import Drawing from "../../Shared/Images/DemoRequestImage.png";
import Book from "../../Shared/Images/Book2.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function CompensationLandingPage() {

  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/contacts/landing/compensation`,
      data: {
        nickName: data.name,
        email: data.email,
      },
    });
    navigate("/thankyou-guide");
  };

  return (
    <Container fluid className="no-padding">
      <LandingPageBanner
        title={
          "[GUÍA] Cómo determinar las compensaciones del personal de manera fácil y transparente."
        }
        textA={
          "Premiar al personal equivocado en la organización puede desatar una 'bola de nieve' de consecuencias dentro de la organización."
        }
        textB={
          "Desde fomentar la mediocridad, hasta la desmotivación y el abandono."
        }
        textC={
          "Contar con un modelo de compensaciones eficaz y transparente ahorra muchos problemas y dolores de cabeza a las organizaciones que cuentan con este."
        }
        logo={Book}
        handleSubmitMethod={handleSubmit}
      />
      <LandingFeature
        title={"¿Qué hay en la guía?"}
        text={"Con esta GUÍA conocerás:"}
        bullets={[
          {
            id: 1,
            header: "Que factores y elementos debes considerar",
            description:
              "Para el diseño de un modelo de compensaciones es necesario determinar los elementos clave que forman parte del cálculo. Concerás cuales son los más populares que generalmente utilizan las organizaciones más exitosas y el porqué.",
          },
          {
            id: 2,
            header: "Los pasos a seguir para guiar el proceso",
            description:
              "Conocerás cuales son los pasos que deberás de seguir para formar tu propio modelo. De igual manera contarás con ejemplos prácticos que podrás usar de referencia para crear el tuyo.",
          },
          {
            id: 3,
            header: "Como utilizar los resultados",
            description:
              "Podrás conocer como utilizar los resultados de tu modelo para determinar las compensaciones adecuadas para cada segmento del personal de la organización.",
          },
        ]}
        logo={Drawing}
      />
      <LandingFeaturedSnippet
        title={"Descubre todo lo que Sphere puede hacer por ti."}
        subtitle={"Una plataforma de RH moderna para empresas actuales."}
      />
      <Footer />
    </Container>
  );
}

export default CompensationLandingPage;
