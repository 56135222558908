import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import Logo from "../../Shared/Images/sphere-logo-white.png";
import Flag from "../../Shared/Images/Spanish.png";

function NavBarEn() {
  const navigate = useNavigate();
  return (
    <Navbar expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">
          <Image src={Logo} fluid />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end align-items-center"
        >
          <Nav>
            <Nav.Link href="/#/">
              <Image src={Flag} fluid />
            </Nav.Link>
            <Nav.Link href="/#/en">Home</Nav.Link>
            <NavDropdown title="Platform" id="basic-nav-dropdown">
              <NavDropdown.Item href="/#/talent/en">Talent</NavDropdown.Item>
              <NavDropdown.Item href="/#/performance/en">
                Performance
              </NavDropdown.Item>
              <NavDropdown.Item href="/#/wellness/en">
                Wellness
              </NavDropdown.Item>
              <NavDropdown.Item href="/#/training/en">
                Training
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://spheremodel.com/blog">Blog</Nav.Link>
            <Nav.Link href="https://sphere-ag.com">
              <strong>Log In</strong>
            </Nav.Link>
            <Button className="sphere" onClick={() => navigate("/demo/en")}>
              Request Demo
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarEn;
