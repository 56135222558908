import React from "react";
import Container from "react-bootstrap/Container";
import NavBar from "../../Navigation/Components/NavBar";
import Footer from "../Components/Footer";
import MainBanner from "../Components/MainBanner";
import SocialProof from "../Components/SocialProof";
import Feature from "../Components/Feature";
import FeatureAlt from "../Components/FeatureAlt";
import TestimonialAlt from "../Components/TestimonialAlt";
import RequestDemo from "../Components/RequestDemo";
import TalentBanner from "../../Shared/Images/TalentPageBanner.png";
import TalentGraphs from "../../Shared/Images/Talento_graficas.png";
import TalentBehaviors from "../../Shared/Images/Talento_comportamientos.png";
import TalentPerspectives from "../../Shared/Images/Talento_perspectivas.png";
import TalentFeedback from "../../Shared/Images/Talento_retroalimentacion.png";
import TalentMap from "../../Shared/Images/Talento_mapa.png";
import CTU from "../../Shared/Images/ctu-bw.png";
import Bafar from "../../Shared/Images/bafar-bw.png";
import Prokarma from "../../Shared/Images/prokarma_bw.png";
import Kalisch from "../../Shared/Images/fierroyacero_bw.png";
import GPA from "../../Shared/Images/gpa-bw.png";
import Fausto from "../../Shared/Images/Fausto.png";

function Talent() {
  return (
    <Container fluid className="no-padding">
      <NavBar />

      <MainBanner
        title={"Impulsa el crecimiento y desarrollo de tu personal"}
        subtitle={"Lleva a tu equipo hacía su mejor versión."}
        logo={TalentBanner}
        buttonText={"Solicitar Demo"}
        linktext={"/demo"}
      />

      <SocialProof
        logo1={CTU}
        logo2={Bafar}
        logo3={Kalisch}
        logo4={Prokarma}
        logo5={GPA}
      />

      <FeatureAlt
        title={"Identifica las áreas de desarrollo"}
        subtitle={"Fácil para todos, no solo RH"}
        text={
          "¿Tu personal cuenta con las habilidades necesarias para ejercer su puesto? ¿Tienes las herramientas para medir e identificarlas? Con Sphere crea evaluaciones orientadas hacia la acción. Retroalimentación constante para gerentes, equipos e individuos."
        }
        logo={TalentGraphs}
        classText={"feature"}
      />

      <TestimonialAlt
        text={
          "'Sphere nos ayuda a tomar decisiones inmediatas para mejorar el desempeño del personal. Tiene muchas ventajas con respecto a otras herramientas por su facilidad de uso y su objetividad. Gracias a Sphere somos capaces de crear planes de desarrollo y generar planes de sucesión para lograr los objetivos estratégicos de Capital Humano.'"
        }
        profilepic={Fausto}
        name={"Fausto Sandoval"}
        profile={"Gerente de Recursos Humanos"}
        company={"ESJ"}
      />

      <Feature
        title={"Versatilidad efectiva"}
        subtitle={"Multiples fuentes de información"}
        text={
          "La mayoría de las organizaciones son complejas. Obtener retroalimentación de los empleados no tiene porque serlo. Con Sphere obtienes retroalimentación de diferentes perspectivas de manera rápida, sencilla y efectiva."
        }
        logo={TalentPerspectives}
        classText={"feature"}
      />

      <RequestDemo
        title={"¿Te gustaría conocer Sphere?"}
        text={
          "Descubre como puede ayudarte a construir una cultura de retroalimentación, desarrollo y alto desempeño."
        }
        buttonText={"Solicitar Demo"}
        linkText={"/demo"}
      />

      <Feature
        title={"Inventario de habilidades"}
        subtitle={"Identifica talento desconocido"}
        text={
          "Con Sphere tienes la herramienta para que el personal brinde retroalimentación anónima de manera honesta. Incrementa el compromiso de tu personal utilizando su experiencia y la dinámica de la organización para tomar decisiones informadas."
        }
        logo={TalentBehaviors}
        classText={"feature"}
      />
      <FeatureAlt
        title={"Reportes al instante"}
        subtitle={"Diseñado para tomar acción"}
        text={
          "Sphere está diseñado para y por líderes en Recursos Humanos. La plaforma está orientada completamente hacia la acción. En Sphere creemos en el poder de la retroalimentación. Desarrolla verdaderamente a tu personal con evaluaciones precisas y objetivas."
        }
        logo={TalentMap}
        classText={"feature-green"}
      />

      <Feature
        title={"Retroalimentación constante"}
        subtitle={"Fomenta una cultura de alto desempeño"}
        text={
          "Dale poder a tu gente al brindarles las herramientas necesarias para dar y recibir retroalimentación de manera segura y constante. Crea planes de desarrollo basados en información actual, confiable y real."
        }
        logo={TalentFeedback}
        classText={"feature"}
      />

      <Footer />
    </Container>
  );
}

export default Talent;
