import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";

function MainBanner({ title, subtitle, logo, buttonText, linktext }) {
  const navigate = useNavigate();
  return (
    <Col className="home-header">
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={5} md={10}>
          <h2>{title}</h2>
          <h4>{subtitle}</h4>
          <Button className="sphere" size="lg" onClick={() => navigate(`${linktext}`)}>
            {buttonText}
          </Button>
        </Col>
        <Col lg={5} md={10}>
          <Image fluid src={logo} />
        </Col>
      </Row>
    </Col>
  );
}

export default MainBanner;
