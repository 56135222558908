import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function LandingFeaturedSnippet({ title, subtitle }) {
  return (
    <Col className="landing-feature-snippet">
      <Row className="justify-content-center align-items-center center">
        <Col lg={8} md={10}>
          <h1 className="mb-4">{title}</h1>
          <h4>{subtitle}</h4>
        </Col>
      </Row>
    </Col>
  );
}

export default LandingFeaturedSnippet;
