import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function Testimonial({ company, profilepic, text, name, profile, title, subtitle, testimonialText }) {
  return (
    <Col className="testimonial">
      <Row className="justify-content-center align-items-center no-margin">
        <Col lg={4} md={10}>
          <h4>{title}</h4>
          <h1>{subtitle} </h1>
          <h5>
            {testimonialText}
          </h5>
        </Col>
        <Col lg={5} md={10}>
          <Col className="testimonial-brand center mb-3">
            <Image fluid src={company} />
          </Col>
          <Col className="testimonial-comment">
            <p>{text}</p>
            <Row className="align-items-center">
              <Col lg={2}>
                <Image fluid src={profilepic} />
              </Col>
              <Col lg={10}>
                <h6>{name}</h6>
                <p>{profile}</p>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Col>
  );
}

export default Testimonial;
