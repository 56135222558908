import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";

function DemoRequestForm({
  handleSubmitMethod,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Col className="demo-request-form">
      <Form onSubmit={handleSubmit((data) => handleSubmitMethod(data))}>
        <Form.Group className="mb-3">
          <Form.Label>* Nombre(s)</Form.Label>
          <Form.Control
            type="text"
            {...register("firstName", {
              required: "Es necesario que ingreses un nombre",
            })}
          />
          <p className="error">{errors.firstName?.message}</p>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>* Apellido(s)</Form.Label>
          <Form.Control
            type="text"
            {...register("lastName", {
              required: "Es necesario que ingreses un apellido",
            })}
          />
          <p className="error">{errors.lastName?.message}</p>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>* Email</Form.Label>
          <Form.Control
            type="email"
            {...register("email", {
              required: "Es necesario que ingreses un correo válido",
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            })}
          />
          <p className="error">{errors.email?.message}</p>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>* Teléfono</Form.Label>
          <Form.Control
            type="phone"
            placeholder="XXX-XXX-XXXX"
            {...register("phone", {
              required: "Es necesario que ingreses un teléfono de contacto",
            })}
          />
          <p className="error">{errors.phone?.message}</p>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>* Empresa</Form.Label>
          <Form.Control
            type="text"
            {...register("company", {
              required: "Es necesario que el nombre de tu organización",
            })}
          />
          <p className="error">{errors.company?.message}</p>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Número de empleados</Form.Label>
          <Form.Select {...register("employees")}>
            <option>Seleccionar rango</option>
            <option value="Tier1">10-50</option>
            <option value="Tier2">51-100</option>
            <option value="Tier3">101-250</option>
            <option value="Tier4">251-500</option>
            <option value="Tier5">501-1000</option>
            <option value="Tier6">1001-5000+</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Button className="sphere" size="lg" type="submit">
            Solicitar demo
          </Button>
        </Form.Group>
      </Form>
    </Col>
  );
}

export default DemoRequestForm;
